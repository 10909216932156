import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_note_view_header = _resolveComponent("note-view-header")!
  const _component_note_view = _resolveComponent("note-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_note_view_header, {
      noteId: _ctx.id,
      name: _ctx.note.name,
      engineType: _ctx.engineType,
      renderType: _ctx.renderType,
      defaultRendering: _ctx.defaultRendering,
      renderTypeChanged: _ctx.renderTypeChanged,
      defaultRenderingChanged: _ctx.defaultRenderingChanged
    }, null, 8, ["noteId", "name", "engineType", "renderType", "defaultRendering", "renderTypeChanged", "defaultRenderingChanged"]),
    _createVNode(_component_note_view, {
      note: _ctx.note,
      renderType: _ctx.renderType
    }, null, 8, ["note", "renderType"])
  ], 64))
}