
  import {computed, defineComponent, reactive, toRefs} from 'vue';
  import useNotes from "@/composables/useNotes";
  import {apiResponseErrorHandle} from "@/composables/errorHandler";
  import { AxiosResponse } from 'axios';
  import {copyToClipboard} from "@/utils/command";
  import {ElMessage} from "element-plus/es";
  import {useStore} from "vuex";

  export default defineComponent({
    name: 'ShareDialog',
    props: {
      noteId: Number,
      engineType: Number,
      dialogVisible: Boolean,
      onClose: Function
    },
    setup(props) {
      const store = useStore();
      const {shareNote, getShareUrl} = useNotes();

      const state = reactive({
        shareUrl: '',
        showShareUrl: false
      });

      const periods = [
        {value: 1, desc: "半个小时"},
        {value: 2, desc: "2个小时"},
        {value: 3, desc: "6个小时"},
        {value: 4, desc: "1天"}
      ];

      const closeDialog = () => {
        props.onClose && props.onClose();
        state.shareUrl = ''
        state.showShareUrl = false;
      }

      const handleDialogClose = (done: () => void) => {
        closeDialog()
        done();
      }

      const copyShareUrl = () => {
        const result = copyToClipboard(state.shareUrl)
        if (result) {
          closeDialog()
          ElMessage({
            showClose: true,
            message: '分享链接已复制',
            type: 'success',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '复制失败！',
            type: 'error',
          });
        }
      }

      const shareNoteCallback = (response: AxiosResponse<any>) => {
        state.shareUrl = getShareUrl(response.data, props.engineType);
        state.showShareUrl = true;
      }

      const shareNoteAsUrl = (sharePeriod: number) => {
        shareNote({
          teamId: store.state.currentTeam?.id,
          noteId: props.noteId,
          sharePeriod: sharePeriod
        }).then(shareNoteCallback)
          .catch(apiResponseErrorHandle('分享失败！'))
      }

      return {
        ...toRefs(state),
        closeDialog,
        handleDialogClose,
        shareNote,
        getShareUrl,
        shareNoteAsUrl,
        copyShareUrl,
        periods,
        visible: computed(() => props.dialogVisible)
      }
    }
  });
