import {useRoute} from "vue-router";

export default function useShowNotePageData() {

  const route = useRoute();

  const parseNoteId = () => {
    const idArg = route.params.noteId;
    const idParam = Array.isArray(idArg) ? idArg[0] : idArg
    return parseInt(idParam);
  }

  const getHash = () => {
    return route.hash;
  }

  return {
    parseNoteId,
    getHash
  }

}