
    import { defineComponent } from 'vue';
    import {ElMessage} from "element-plus/es";
    import {UPDATE_DEFAULT_RENDERING} from "@/constants/api";

    export default defineComponent({
        name: 'DefaultRenderingSelection',
        props: {
            id: Number,
            selected: Number,
            dialogVisible: Boolean,
            success: Function,
            cancel: Function
        },
        watch: {
            dialogVisible(value, old) {
                this.visible = value
            },
            selected(value, old) {
                this.form.defaultRendering = String(value)
            }
        },
        data() {
            return {
                visible: this.dialogVisible,
                loading: false,
                form: {
                    defaultRendering: String(this.selected),
                    id: this.id
                }
            }
        },
        methods: {
            confirm() {
                this.loading = true;
                const vm = this;
                this.axios.patch(UPDATE_DEFAULT_RENDERING, {
                    defaultRendering: parseInt(this.form.defaultRendering),
                    id: this.id,
                    teamId: this.$store.state.currentTeam?.id
                }).then(function (response) {
                    vm.loading = false
                    if (vm.success) {
                        vm.success(vm.form.defaultRendering)
                    }
                    ElMessage({
                        showClose: true,
                        message: '更改成功',
                        type: 'success',
                    });
                }).catch(function (error) {
                    vm.loading = false;
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '更改失败',
                            type: 'error',
                        });
                    }
                });
            },
            cancelClick() {
                if (this.cancel) {
                    this.cancel();
                }
            }
        }
    });
